@import "../../../variables.scss";
.token-banner{
  height: 60%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 70px;
}

.token-banner-icon{
  ul{
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      background-color: transparent;

      li {
        float: left;
        margin-left: 15px;

        img{
          height: 30px;
          width: 30px;
        }
        
      }:first-child { margin-left: 0px; }
  }
}