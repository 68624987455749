@import "../../../variables.scss";

.user-profile-detail-banner{
  height: 60%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 70px;
}




