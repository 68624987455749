.offer-container {
    height: 320px;
  }
  .offer-body {
    height: 135px;
  }
  .offer-col {
    margin-top: 120px !important;
  }
  
  @media only screen and (max-width: 1400px) {
    .offer-container {
      height: 360px;
    }
    .offer-body {
      height: 170px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .offer-container {
      height: 390px;
    }
    .offer-body {
      height: 210px;
    }
  }
  @media only screen and (max-width: 992px) {
    .offer-container {
      height: 320px;
    }
    .offer-body {
      height: 135px;
    }
  }
  