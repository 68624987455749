@import '../../../variables.scss';
.sales-banner {
  height: 90%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  .search-sales-design {
    background: linear-gradient(
      126.59deg,
      rgba(255, 255, 255, 0.05) 22.11%,
      rgba(255, 255, 255, 0.8) 63.08%
    );
    box-shadow: 0px 2.8743px 17.2458px -0.718575px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(14.3715px);
    margin-top: 90px;
    border: 1px solid;
    border-color: $mercurx-primary !important;

    .form-control {
      border: 0px;
      background-color: rgba($color: black, $alpha: 0.8);
      color: white !important;
      border-radius: 0px;
    }
    .form-control:focus {
      background-color: black !important;

      border: 1px solid;
      border-color: $mercurx-primary !important;
      outline: 0 !important;
      box-shadow: none !important;
    }
  }

  .search-sales-icon {
    border: 0px;
    background: transparent;
  }

  .search-sales-input {
    background: transparent;
    border-left: 0px;
  }
  .white-placeholder::placeholder {
    color: rgb(191, 180, 180);
    opacity: 1;
  }
}
