@import "../../variables.scss";

.modal .modal-content{
    background: #f5f5f5;
}
.modal-row{
    background:white;
    border: 1px;
    border-color: #EEEEEE;
    border-style: solid;
    border-radius: 4.8px;
    height: 58px;
    margin: 10px;

}
.wallet-icon{
    height: 32px;
    width: auto;
    border-radius: 20px;
}
.new-logo{
    color: $mercurx-primary;
    background: rgba(228, 0, 125, 0.18);;
    border-radius: 27px;
    height: 18px;
    width: auto;
}
.coming-soon-logo{
    color: $white;
    background: $gray-500;
    border-radius: 27px;
    height: 18px;
    width: auto;
}