.home-banner{
    height: 600px;
    justify-content: center;
    display: flex;
    flex-direction: column;

  }


  @media only screen and (max-width: 1200px) {
    .home-banner{
      height: 400px;
  }
  }

  @media only screen and (max-width: 992px) {
    .home-banner{
      height: 280px;
  }
  }

