.wallet-account-history-modal {
  z-index: 1056 !important;
}
.wallet-account-history-table {
  height: 640px;
  overflow: auto;
}
.border-bottom-detail {
  border-bottom: 1px solid black;
}

.link {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.table thead th {
  border-bottom: none;
}

.table th {
  text-align: center;
}

.history-table-design {
  border-collapse: separate;
  border-spacing: 0 1em;
  width: 100%;

  tbody {
    tr {
      background: #fafafa;
      box-shadow: 0px 2px 4px 1px rgba(138, 136, 136, 0.22);
      border-radius: 4.8px;
      td {
        height: 30px;
        text-align: center;
        padding-top: 2%;
      }
    }
  }
}

//   .custom-table-row tr{
//     margin-bottom: 0 !important;
//   }
