@import "../../variables.scss";
.text-dark-light {
    color: #6c757d; /* veya istediğiniz renk kodunu buraya yazın */
  }
  
  .text-dark-light a {
    color: inherit; /* Bu, bağlantı rengini, üst öğenin rengini (text-dark-light) alacak şekilde ayarlar */
    text-decoration: none; /* Varsayılan alt çizgiyi kaldırmak için ekleyebilirsiniz */
  }
  
  .text-dark-light a:hover {
    text-decoration: underline; /* Üzerine gelindiğinde alt çizgi eklemek için */
    color:$mercurx-primary;
  }
  