@import "../../variables.scss";

.account-div{
    height: 21px;
    left: 70px;
    color:$mercurx-t-head-color;
}
.titles{
    color: #FFFFFF;
    letter-spacing: 0.05em;
}
.titles.network-name{
    max-width:200px;
}
.metamask-icon{
    border-radius: 20px;
}
.metamask-div{

    background: $mercurx-light;
    border-radius: 4.8px;
    color: $mercurx-primary;
    letter-spacing: 0.01em;
}